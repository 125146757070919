import React from "react";

import Page from 'containers/page';
import Imprint from 'containers/Imprint';


export default () => {
  return <Page seoProps={{
    title: "Impressum",
  }}>
    <Imprint/>
  </Page>
}